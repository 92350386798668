import React from 'react'
import Profile from '../src/components/Profile'
import UserCreate from '../src/components/UserCreate'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";

import './App.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons'

library.add(faPlus, faUpload)

export default function App() {

  return (
        <Router>
          {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/user-create">User create</Link>
            </li>
          </ul> 

          <hr /> */}
          <Switch>
          <Route exact path="/">
            <UserCreate />
          </Route>
          <Route path="/user-create">
            <UserCreate />
          </Route>
          <Route path="/profile">
            <Profile/>
          </Route>
        </Switch>
      </Router>
    );

}
