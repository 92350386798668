import React from 'react'
import './UserCreate.scss'
import Panel from '../Profile/Panel'


export default function UserCreate(){

    return (
        <div className="user-create-container">
            <div className="mini-panel">
                <Panel />
            </div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div class="card-header">
				<div class="steps">
						<div class="step active">
							<span>
									1
							</span>	
						</div>	
						<div class="step">
							<span>
									2
							</span>	
						</div>	
						<div class="step">
							<span>
									3
							</span>	
						</div>	
			        </div>
		        </div>
        </div>
    )
}