import React from 'react'
import SubmitButton from '../SubmitButton/SubmitButton'
import AddButton from '../AddButton/AddButton'
import UploadButton from '../UploadButton/UploadButton'
import './Sections.scss'
import picCV from '../../assets/img/word.png'

export default function Sections() {

    return (
        <div>
            <div className="worko-tabs">  
                <input className="state" type="radio" title="tab-one" name="tabs-state" id="tab-one" defaultChecked />
                <input className="state" type="radio" title="tab-two" name="tabs-state" id="tab-two" />
                <input className="state" type="radio" title="tab-three" name="tabs-state" id="tab-three" />
                <input className="state" type="radio" title="tab-four" name="tabs-state" id="tab-four" />
            <div className="tabs flex-tabs">
                <label htmlFor="tab-one" id="tab-one-label" className="tab">Contact</label>
                <label htmlFor="tab-two" id="tab-two-label" className="tab">About me</label>
                <label htmlFor="tab-three" id="tab-three-label" className="tab">First Job CV</label>
                <label htmlFor="tab-four" id="tab-four-label" className="tab">Profesional CV</label>

                    <div id="tab-one-panel" className="panel-tab active">
                    <div className="section-content">
                        <h3>Contact</h3>
                        <form>
                            <div className="imput-content">
                                <label className="flex-item-1" htmlFor="email"> Email: </label>
                                <input className="flex-item-1" type="email" name="email" />
                            </div>
                            <div className="imput-content">
                                <label className="flex-item-1" htmlFor="name"> Name: </label>
                                <input className="flex-item-1" type="text" name="name" />
                            
                            </div>
                            <div className="imput-content">
                                <label className="flex-item-1" htmlFor="lastName"> LastName: </label>
                                <input className="flex-item-1" type="text" name="lastName" />
                                
                            </div>
                            <div className="imput-content">
                                <label htmlFor="phone"> Phone: </label>
                                <select name="countries" id="countries">
                                    <option value="Ireland">+353</option>
                                    <option value="Mexico">+56</option>
                                    <option value="Argentine">+54</option>
                                    <option value="Spain">+34</option>
                                </select>
                                <input type="text" name="phone" />
                                <AddButton name={'Add phone number'}></AddButton>
                            </div>
                            <div className="imput-content">
                                <div></div>
                                <SubmitButton name={'Save'}></SubmitButton>
                            </div>
                        </form>
                    </div>
                    </div>
                    <div id="tab-two-panel" className="panel-tab">
                        <div className="section-content">
                        <h3>About me</h3>
                            <form>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="nationality"> Nationality: </label>
                                    <div className="flex-item-2">
                                        <input type="text" name="nationality" defaultValue="Mexico / Colombia / UK / Australia" />
                                    </div>
                                        <AddButton name={'Add other nationality'}></AddButton>
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="nationality"> Program of interest: </label>
                                    <input className="flex-item-2" type="text" name="nationality" defaultValue="Language / Certifications / Universities / Masters" />
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="lastName"> Option to work part time: </label>
                                    <div className="flex-item-2">
                                        <input type="radio" id="yes" name="lastName" value="yes" />
                                        <label htmlFor="male">Yes</label>
                                        <input type="radio" id="no" name="lastName" value="female" />
                                        <label htmlFor="no">No</label>
                                    </div>
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="age"> Age: </label>
                                    <input className="flex-item-1" type="number" name="age" />
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="education"> Last Education Level: </label>
                                    <input className="flex-item-2" type="text" name="education" defaultValue="Primary / Secondary / High School / Bachelor's Degree / Postgraduate / Master / Doctoral degree" />
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="dicipline"> Dicipline: </label>
                                    <input className="flex-item-2" type="text" name="dicipline" defaultValue="Humanities / Anthropology / Archeology / Economics" />
                                </div>
                                <div className="imput-content">
                                    <label className="flex-item-1" htmlFor="countries"> Countries of interest: </label>
                                    <input className="flex-item-2" type="text" name="countries" defaultValue="Ireland / Canada / Australia / England" />
                                </div>
                                <div className="imput-content">
                                    <div></div>
                                    <SubmitButton name={'Search'}></SubmitButton>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="tab-three-panel" className="panel-tab">
                        <div className="section-content">
                            <h3>First Job CV</h3>
                            <div className="imput-content">
                                <p>Encontrar tu primer trabajo puede ser un poco abrumador, pero con estos tips estarás más que listo</p>
                            </div>
                            <br/>
                            <br/>
                            <div className="pic-cv">
                                <img src={picCV} alt="Irish templates CV" className="img-cv"/>
                                <img src={picCV} alt="Irish templates CV" className="img-cv"/>
                            </div>
                            <p className="title-cv">Irish templates CV</p>
                        </div>
                    </div>
                    <div id="tab-four-panel" className="panel-tab">
                    <div className="section-content">
                        <h3>Professional CV</h3>
                            <div className="imput-content">
                                <p>Aplica a nuestra red para realizar prácticas profesionales.</p>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                        <div className="imput-content">
                            <UploadButton name={'Upload CV'}/>
                            <div>
                                <div className="pic-cv">
                                    <img src={picCV} alt="Irish templates CV" className="img-cv"/>
                                    <img src={picCV} alt="Irish templates CV" className="img-cv"/>
                                </div>
                                <p className="title-cv">Irish templates CV</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}