import React from 'react'
import Panel from './Panel'
import Sections from './Sections'

import './Profile.scss'

export default function Profile(){
    const resume = 'Mercury is the closest planet to the Sun and the smallest one in the Solar System. In fact, it’s only a bit larger than our Moon';
    return (
        <div className="profile-container">
            <div className="panel">
                <Panel resume={resume} />
            </div>
            <div className="sections">
                <Sections/>
                <hr className="line"></hr>
            </div>
        </div>);
}