import React from 'react'
import  './Panel.scss'
import picProfile from '../../assets/img/pic_profile.png'

export default function profilePanel (props) {
    const { resume } = props
    return (
        <div className="panel-background">
          <figure className="pic-profile">
            <img src={picProfile} alt="Profile" className="img-profile"/>
            <hr/>
            <figcaption>Nick Name</figcaption>
            <hr/>
          </figure>
          <div className="details-profile">
            <h4 className="title-details-profile"> MY PROFILE </h4>
            <p> {resume} </p>
          </div>
        </div>
      );
}